import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // User Routes
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'google-authenticator', element: <GoogleAuthenticator /> },
        // { path: 'manage-admin', element: <ManageAdmin /> },
        // { path: 'manage-admin/form', element: <AdminForm /> },
        // { path: 'manage-group', element: <ManageGroup /> },
        // { path: 'manage-group/form', element: <GroupForm /> },
        // { path: 'admin-amendment', element: <AdminAmendment /> },
        // { path: 'activity-log', element: <ActivityLog /> },
        // { path: 'inactive-log', element: <InactiveLog /> },
        { path: 'account', element: <AccountGeneral /> },
      ],
    },

    // Credit Route
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'credit', element: <CreditReport /> },
      ],
    },

    // Police Report Route
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'police-report', element: <ManagePoliceReport /> },
      ],
    },

    // {
    //   path: '/',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: 'dashboard', element: <Dashboard /> },
    //     { path: 'calendar', element: <Calendar /> },
    //   ],
    // },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD
const Dashboard = Loadable(lazy(() => import('../views/home/Dashboard')));

// USER
const GoogleAuthenticator = Loadable(lazy(() => import('../views/user/GoogleAuthenticator')));
const DutyScheduler = Loadable(lazy(() => import('../views/user/DutyScheduler')));
const ManageAdmin = Loadable(lazy(() => import('../views/user/ManageAdmin')));
const AdminForm = Loadable(lazy(() => import('../views/user/AdminForm')));
const ManageGroup = Loadable(lazy(() => import('../views/user/AdminGroup')));
const GroupForm = Loadable(lazy(() => import('../views/user/GroupForm')));
const AdminAmendment = Loadable(lazy(() => import('../views/user/AdminAmendment')));
const ActivityLog = Loadable(lazy(() => import('../views/user/ActivityLog')));
const InactiveLog = Loadable(lazy(() => import('../views/user/InactiveLog')));
const AccountGeneral = Loadable(lazy(() => import('../sections/@dashboard/user/account/AccountGeneral')));

// CREDIT REPORT
const CreditReport = Loadable(lazy(() => import('../views/credit/CreditReport')));

// POLICE REPORT
const ManagePoliceReport = Loadable(lazy(() => import('../views/policeReport/ManagePoliceReport')));

//----------------------------------------------------------------------------------------------------------------------

const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
