// routes
import { PATH_DASHBOARD, PATH_USER, PATH_CREDIT_REPORT, PATH_POLICE_REPORT } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const subMenuIcon = (ic) => <Iconify icon={ic} />;

const navConfig = [
  {
    subheader: '',
    items: [
      // {
      //   title: 'Manage Role',
      //   path: PATH_USER.manageGroup,
      //   icon: subMenuIcon('fa-solid:user-cog'),
      // },
      // {
      //   title: 'Manage Admin',
      //   path: PATH_USER.manageAdmin,
      //   icon: subMenuIcon('bi:person-fill'),
      // },
      // {
      //   title: 'Dashboard',
      //   path: PATH_DASHBOARD.root,
      //   icon: subMenuIcon('bxs:dashboard'),
      // },
      {
        title: 'Manage Police Report',
        path: PATH_POLICE_REPORT.root,
        icon: subMenuIcon('map:police'),
      },
      {
        title: 'Search Credit Report',
        path: PATH_CREDIT_REPORT.root,
        icon: subMenuIcon('fa:credit-card-alt'),
      },
      // {
      //   title: 'Activity Log',
      //   path: PATH_USER.activityLog,
      //   icon: subMenuIcon('fa-solid:user-clock'),
      // },
      // {
      //   title: 'Google Authenticator',
      //   path: PATH_USER.googleAuth,
      //   icon: subMenuIcon('ant-design:google-outlined'),
      // },
    ],
  },
];

export default navConfig;
