import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogActions, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useDispatch, useSelector } from '../redux/store';
import { resetConfirmState, setOpen } from '../redux/slices/confirm';
// import { resetConfirmState } from '../redux/slices/confirm';

// ----------------------------------------------------------------------

export default function CustomConfirm() {
    const {isLoading, open, title, subTitle, onConfirm, onCancel} = useSelector(state => state.confirm);

    const dispatch = useDispatch();

    useEffect(() => () => dispatch(resetConfirmState()), []);

    const handleCancel = () => {
        if(onCancel && typeof onCancel === "function"){
            onCancel();
        }
        dispatch(setOpen(false));
        dispatch(resetConfirmState());
    }
    
    const handleConfirm = () => {
        if(onConfirm && typeof onConfirm === "function"){
            onConfirm();
        }
        dispatch(setOpen(false));
    }

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCancel}>
        <DialogTitle>
            <Typography fontWeight={"bold"}>{title}</Typography>
            <Typography sx={{mt: 1}} variant='body2'>{subTitle}</Typography>
        </DialogTitle>
        <DialogActions>
            <Button
                variant="outlined"
                color="inherit"
                onClick={handleCancel}
            >
                No
            </Button>

            <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                onClick={handleConfirm}
            >
                Yes
            </LoadingButton>
        </DialogActions>
        </Dialog>
  );
}
