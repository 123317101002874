import React, { useState, forwardRef } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { closeCall, closeHardware } from '../../redux/slices/settings';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

import { DialogAnimate } from '../../components/animate';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const dispatch = useDispatch();

  const { isOpenCall, isOpenHardware } = useSelector((state) => state.settings);

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const handleCloseCall = () => {
    dispatch(closeCall());
  };

  const handleCloseHardware = () => {
    dispatch(closeHardware());
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>

      {isOpenCall && <CallClient isOpenCall={isOpenCall} handleCloseCall={handleCloseCall} />}

      <Hardware isOpenHardware={isOpenHardware} handleCloseHardware={handleCloseHardware} />
    </Box>
  );
};

export default DashboardLayout;

const StyledPhoneKeyPads = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  background: 'rgb(242, 247, 255)',
  '&:hover': {
    background: 'rgb(215 220 229)',
    transform: 'scale(1.1)',
  },
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CallClient = ({ isOpenCall, handleCloseCall }) => {
  const [toCall, setToCall] = useState('');

  const handleCallerNumber = (e) => {
    setToCall(e.target.value);
  };

  const handleKeypad = (val) => {
    if (toCall.length < 12) {
      setToCall((prevState) => `${prevState + val}`);
    }
  };

  return (
    <Dialog open={isOpenCall} onClose={handleCloseCall} TransitionComponent={Transition} fullWidth maxWidth="xs">
      <DialogTitle>Enter Phone Number</DialogTitle>

      <Box sx={{ width: 182, mx: 'auto', textAlign: 'center', mt: 3 }}>
        <TextField
          type="tel" // setting this to number wont apply maxLength
          name="toCall"
          label="Phone Number"
          placeholder="Enter Phone Number"
          variant="outlined"
          size="small"
          fullWidth
          value={toCall}
          onChange={(e) => handleCallerNumber(e)}
          inputProps={{ maxLength: 12 }}
          key={1}
        />

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('1')}>
              1
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('2')}>
              2
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('3')}>
              3
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('4')}>
              4
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('5')}>
              5
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('6')}>
              6
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('7')}>
              7
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('8')}>
              8
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('9')}>
              9
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4} />

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('0')}>
              0
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4} />
        </Grid>
      </Box>

      <DialogActions sx={{ mx: 'auto' }}>
        <Box
          sx={{
            width: 50,
            height: 50,
            borderRadius: '50%',
            background: '#00AB55',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              background: '#05703b',
              transform: 'scale(1.1)',
            },
            cursor: 'pointer',
          }}
        >
          <Iconify icon={'fluent:call-48-filled'} sx={{ fontSize: 30, color: 'white' }} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            mr: '-45px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              transform: 'scale(1.1)',
            },
          }}
          onClick={() => setToCall((prevState) => prevState.substring(0, prevState.length - 1))}
        >
          <Iconify icon={'fa6-solid:delete-left'} sx={{ fontSize: 30 }} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const Hardware = ({ isOpenHardware, handleCloseHardware }) => {
  return (
    <DialogAnimate open={isOpenHardware} onClose={handleCloseHardware}>
      <DialogTitle>Hardware Setting</DialogTitle>

      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              type="textarea"
              name="micDevices"
              label="Mic Devices"
              placeholder="Enter Phone Number"
              variant="outlined"
              size="small"
              fullWidth
              rows={3}
              value={'Unknown Audio Output Device 1'}
              // value={toCall}
              // onChange={(e) => setToCall(e.target.value)}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              type="textarea"
              name="speakerDevices"
              label="Speaker Devices"
              placeholder="Enter Phone Number"
              variant="outlined"
              size="small"
              fullWidth
              value={'Unknown Audio Output Device 1'}
              // value={toCall}
              // onChange={(e) => setToCall(e.target.value)}
            />
          </Grid>
        </Grid>

        <Button size="small" variant="contained" color="primary" sx={{ mt: 2 }}>
          Load Devices
        </Button>

        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ mt: 2, ml: 1 }}
          onClick={() => handleCloseHardware()}
        >
          Cancel
        </Button>
      </Box>
    </DialogAnimate>
  );
};
