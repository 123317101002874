import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Button,
  Box,
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Select,
  Input,
  TextField,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import jwtDecode from 'jwt-decode';
import conObj from '../../../utils/connection-assistant';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { useDispatch } from '../../../redux/store';

// ----------------------------------------------------------------------

function isObject(variable) {
  let res = false;
  if (typeof variable === 'object' && variable !== null && !Array.isArray(variable)) {
    res = true;
  }

  return res;
}

export default function LoginForm() {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const [qrCode, setQrCode] = useState({
    show: false,
    qrImage: '',
  });

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const [showAuthField, setShowAuthField] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [loginFields, setLoginFields] = useState({
    username: '',
    password: '',
    userCaptchaInput: '',
    locale: '',
    country: '',
  });

  const [authCode, setAuthCode] = useState('');

  const [loginAuthPayload, setLoginAuthPayload] = useState({});

  const [errors, setErrors] = useState({});

  const [authError, setAuthError] = useState({});

  const [showOTPModal, setShowOTPModal] = useState(false);

  const [otp, setOtp] = useState('');

  const [smsAuthPayload, setSmsAuthPayload] = useState(null);

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      setIsSubmitting(true);

      const reqPayload = {
        username: loginFields.username,
        password: loginFields.password,
        captchaId: 'test',
        captcha: 'test',
      };

      conObj
        .post('auth/lgn', reqPayload)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              const { gAuthSetup } = res.data;
              setLoginAuthPayload(res.data);
              if(gAuthSetup?.qrImage) setQrCode({ show: true, qrImage: gAuthSetup.qrImage });
              else {
                setAuthCode('');
                setShowAuthField(true);
              }
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            const { errorCode, errorData, message, title } = e.response.data;
            setErrors({ afterSubmit: { message } });
          } else {
            //
          }
          console.log(`Error: ${e.message}`);
        })
        .then(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleGoogleAuth = (e) => {
    e?.preventDefault();
    setIsLoggingIn(true);
    const { jwt, queueId } = loginAuthPayload;

    // set header authorization
    conObj.defaults.headers.common.Authorization = `Bearer ${jwt}`;

    const reqPayload = {
      queueId,
      code: authCode,
    };

    conObj
      .post('auth/gauth', reqPayload)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            // do login
            // setShowAuthField(false);
            localStorage.setItem('jwt', res.data);
            try {
              login();
            } catch (error) {
              console.error(error, 'auth');
            }
          }
        }
      })
      .catch((e) => {
        const respData = e.response.data;

        if (e.response.status === 400) {
          if (isObject(respData)) {
            const { errorCode, errorData, message, title, errors } = respData;
            setAuthError({ show: true, message: message || title });
          } else {
            setAuthError({ show: true, message: respData });
          }
        } else if (e.response.status === 401) {
          if (isObject(respData)) {
            const { errorCode, errorData, message, title } = respData;
            setAuthError({ show: true, message });
          } else {
            setAuthError({ show: true, message: respData });
          }
        }

        console.error(`Error: ${e.message}`);
      })
      .then(() => {
        // clear
        // make sure to perform this so that we rely on jwt from localstorage
        delete conObj.defaults.headers.common.Authorization;
      }).finally(() => setIsLoggingIn(false));
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setLoginFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const isValid = () => {
    let valid = true;
    const { locale, country, username, password, userCaptchaInput } = loginFields;
    const _error = {};

    if (locale) {
      _error.locale = '';
    } else {
      _error.locale = 'Language is Required';
      valid = false;
    }

    if (country) {
      _error.country = '';
    } else {
      _error.country = 'Country is Required';
      valid = false;
    }

    if (username.trim()) {
      _error.username = '';
    } else {
      _error.username = 'Username is Required';
      valid = false;
    }

    if (password.trim()) {
      _error.password = '';
    } else {
      _error.password = 'Password is Required';
      valid = false;
    }

    if (userCaptchaInput.trim()) {
      _error.userCaptchaInput = '';
      if (validateCaptcha(loginFields.userCaptchaInput.trim()) === true) {
        _error.userCaptchaInput = '';
      } else {
        _error.userCaptchaInput = 'Captcha is not matched';
        valid = false;
      }
    } else {
      _error.userCaptchaInput = 'Captcha is Required';
      valid = false;
    }

    setErrors(_error);

    return valid;
  };

  return (
    <>
      <Box component={"form"} onSubmit={(e) => handleSubmit(e)} action="#">
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <TextField
            value={loginFields.locale}
            name="locale"
            label="Language"
            onChange={(e) => handleChange(e)}
            placeholder="Language"
            select
            SelectProps={{ native: true }}
            error={errors?.locale}
            helperText={errors?.locale ? errors?.locale : ''}
            required
          >
            <option value="" />
            <option value="English">English</option>
            <option value="Bahasa Melayu">Bahasa Melayu</option>
          </TextField>

          <TextField
            value={loginFields.country}
            name="country"
            label="Country"
            onChange={(e) => handleChange(e)}
            placeholder="Country"
            select
            SelectProps={{ native: true }}
            error={errors?.country}
            helperText={errors?.country ? errors?.country : ''}
            required
          >
            <option value="" />
            <option value="Malaysia">Malaysia</option>
            <option value="Thailand">Thailand</option>
          </TextField>

          <TextField
            name="username"
            value={loginFields.username}
            label="Username"
            onChange={(e) => handleChange(e)}
            error={errors?.username}
            helperText={errors?.username ? errors?.username : ''}
            required
          />

          <TextField
            name="password"
            value={loginFields.password}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={errors?.password}
            helperText={errors?.password ? errors?.password : ''}
            onChange={(e) => handleChange(e)}
            required
          />

          <Stack direction={'row'} spacing={1} alignItems="unset">
            <TextField
              name="userCaptchaInput"
              label="Enter Code"
              onChange={(e) => handleChange(e)}
              error={errors?.userCaptchaInput}
              helperText={errors?.userCaptchaInput ? errors?.userCaptchaInput : ''}
              sx={{ flexGrow: "1" }}
            />
            <Box
              sx={{
                height: 56,
                border: '1px solid rgb(220, 224, 228)',
                borderRadius: '8px',
                pt: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LoadCanvasTemplateNoReload />
            </Box>
          </Stack>
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Checkbox name="remember" label="Remember me" />
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack> */}

        <LoadingButton sx={{mt: 2}} type='submit' fullWidth size="large" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Box>

      <Dialog
        open={qrCode.show}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setQrCode((prevState) => ({ ...prevState, show: false }));
          }
        }}
      >
        <DialogTitle>Google Authenticator</DialogTitle>

        <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', mt: 2, p: 3 }}>
          <Typography variant="caption" color="primary" sx={{ mb: 1 }}>
            Scan the QR code then click next to enter the Authentication Code
          </Typography>

          <img src={`${qrCode.qrImage}`} alt="QRCode" width="200px" />
        </Box>

        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setQrCode((prevState) => ({ ...prevState, show: false }));
              setShowAuthField(true);
            }}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAuthField}
        disableEscapeKeyDown
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setShowAuthField(false);
          }
        }}
      >
        <DialogTitle>Authenticate</DialogTitle>
        <form action="#" onSubmit={(e) => handleGoogleAuth(e)}>
          <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', px: 3, pt: 3, textAlign: 'center' }}>
            <Typography variant="subtitle2" color="primary" sx={{ mb: 3 }}>
              Please enter your 6-digit authentication code from Google Authenticator app.
            </Typography>

            <TextField autoFocus name="authCode" value={authCode} label="Code" onChange={(e) => setAuthCode(e.target.value)} required/>

            {!!authError.show && (
              <Alert sx={{ width: '100%', mt: 3 }} severity="error">
                {authError.message}
              </Alert>
            )}
          </Box>

          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />

            {qrCode.qrImage !== '' && (
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  setShowAuthField(false);
                  setQrCode((prevState) => ({ ...prevState, show: true }));
                  setAuthCode('');
                  setAuthError({});
                }}
              >
                Back
              </Button>
            )}

            <LoadingButton loading={isLoggingIn} type="submit" variant="contained" color="primary">
              Ok
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
