import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

const initialState = {
    isLoading: false,
    open: false,
    title: "",
    subTitle: "This cannot be undone!",
    onConfirm: null,
    onCancel: null
}

const slice = createSlice({
    name: "confirm",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setOpen(state, action) {
            state.open = action.payload;
        },
        setTitle(state, action) {
            state.title = action.payload;
        },
        setSubTitle(state, action) {
            state.subTitle = action.payload;
        },
        setOnConfirm(state, action) {
            state.onConfirm = action.payload;
        },
        setOnCancel(state, action) {
            state.onCancel = action.payload;
        },
        resetConfirmState(state){
            state.title = initialState.title;
            state.subTitle = initialState.subTitle;
            state.onConfirm = initialState.onConfirm;
            state.onCancel = initialState.onCancel;
            state.isLoading = initialState.isLoading;
            state.open = initialState.open;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {setLoading, setOpen, resetConfirmState} = slice.actions;

export const openConfirm = (title, onConfirm, onCancel, subTitle) => {
    try{
        if(title) dispatch(slice.actions.setTitle(title)); else throw new Error("Title is required!");
        if(subTitle) dispatch(slice.actions.setSubTitle(subTitle));
        if(onConfirm) dispatch(slice.actions.setOnConfirm(onConfirm));
        if(onCancel) dispatch(slice.actions.setOnConfirm(onCancel));
    } catch(error) {
        console.log(error);
        return;
    }
    dispatch(slice.actions.setOpen(true));
};
